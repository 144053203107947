import { publish as publishMessage } from "./messages";
import * as passbackHelper from "./helpers/passbackHelper";
import trackAd from "./trackAd";
import * as cookieHelper from "./helpers/cookieHelper";
import { slotNames } from "../static/slotNames";
import triggerFluidVideo from "./fluidRichMediaPremium";
import { parseEvent } from "./slotEvent";
import { createReportButton } from "./adReportButton";
import { setAdInfoClickLink } from "./gdpr";
import { getSlotConfig } from "./helpers/bamHelper";
import noScaleLineitems from "../static/lineItemsToNotScale";
import excludedSites from "../static/excludedSitesFromTintin";

function eventListeners(bauConfig) {
  googletag.pubads().addEventListener("slotRenderEnded", (evt) => slotRenderEndedListener(evt, bauConfig));
  googletag.pubads().addEventListener("impressionViewable", impressionViewableEventListener);

  window.addEventListener("message", messageHandler);
}

function slotRenderEndedListener(evt, bauConfig) {
  const adUnit = parseEvent(evt, "slotRenderEnded");
  triggerSlotRenderEndedEvents(adUnit, bauConfig);
  createSlotAttributes(adUnit);
  setFrequencyCookie(adUnit, bauConfig.frequencyCookie);
  triggerFluidVideo(adUnit);
  if (!excludedSites.find((it) => window.location.hostname.replace(/^www\./, "").match(it))) {
    createReportButton(adUnit, bauConfig);
  } else {
    setAdInfoStyling(adUnit, bauConfig);
    if (bauConfig.isInternal) {
      createReportButton(adUnit, bauConfig);
    }
  }

  // Unique logic for previewing ads with a generated GAM preview link.
  if (adUnit.adData.lineItemId === null && new URLSearchParams(location.href).get("lineItemId")) {
    adUnit.adData.lineItemId = Number(new URLSearchParams(location.href).get("lineItemId"));
  }

  if (adUnit.adData.lineItemId && !noScaleLineitems.includes(adUnit.adData.lineItemId) && (adUnit.name === "rich_media_premium" || (adUnit.name === "mob_rich_media_premium" && !evt.slot.getHtml().includes("8b38f539-3ae1-4df4-af53-b2c9013bbe59")))) {
    const slotElement = document.getElementById(adUnit.id);
    scaleRichMediaPremium(slotElement);
    window.addEventListener("resize", () => {
      scaleRichMediaPremium(slotElement);
    });
  } else if (adUnit.name === "rich_media_premium") {
    const slotElement = document.getElementById(adUnit.id);
    slotElement.style.display = "flex";
    slotElement.style.justifyContent = "center";

    // We scale down the ad once if needed but never up.
    const maxWidthScale = window.visualViewport.width / 1920;
    const maxHeightScale = window.visualViewport.height / 1080;

    const scale = Math.min(maxHeightScale, maxWidthScale, 1);

    slotElement.firstChild.style.transform = `scale(${scale})`;
    slotElement.firstChild.style.transformOrigin = "center top";
  } else if (adUnit.name === "mob_rich_media_premium") {
    const slotElement = document.getElementById(adUnit.id);
    slotElement.style.display = "flex";
    slotElement.style.justifyContent = "center";

    const maxWidthScale = window.visualViewport.width / adUnit.size.width;
    const maxHeightScale = window.visualViewport.height / adUnit.size.height;

    const scale = Math.min(maxHeightScale, maxWidthScale, 1);

    slotElement.firstChild.style.transform = `scale(${scale})`;
    slotElement.firstChild.style.transformOrigin = "center top";
  }
}

function scaleRichMediaPremium(slotElement) {
  if (!window?.visualViewport || !slotElement) return;

  const safeArea = {
    desktop: {
      width: 1920,
      height: 817 // 777 + 40
    },
    mob: {
      width: 640,
      height: 793 // 753 + 40
    }
  };

  let maxWidthScale;
  let maxHeightScale;

  if (slotElement.dataset.slotName === "rich_media_premium") {
    maxWidthScale = window.visualViewport.width / safeArea.desktop.width;
    maxHeightScale = (window.visualViewport.height - slotElement.getBoundingClientRect().y) / safeArea.desktop.height;
  } else {
    maxWidthScale = window.visualViewport.width / safeArea.mob.width;
    maxHeightScale = (window.visualViewport.height - slotElement.getBoundingClientRect().y) / safeArea.mob.height;
  }
  const scale = Math.min(maxHeightScale, maxWidthScale);

  slotElement.style.display = "flex";
  slotElement.style.justifyContent = "center";

  slotElement.firstChild.style.transformOrigin = "top";
  slotElement.firstChild.style.transform = `scale(${scale})`;
}

function setAdInfoStyling(adUnit, bauConfig) {
  if (!bauConfig.enableAdInfo) return;
  const slotElement = document.getElementById(adUnit.id);
  if (!slotElement) return;
  const slotInfo = bauConfig.slotNameConfig[adUnit.name];
  const slot = getSlotConfig(adUnit.number, slotInfo.slots);

  if (bauConfig.adInfo && bauConfig.adInfo.link) {
    if (slot.adInfoEnabled && !adUnit.isEmpty) {
      slotElement.classList.add("ad-info");
      setAdInfoClickLink(slotElement, bauConfig.adInfo.link);
    }
  }
}

function setFrequencyCookie(adUnit, frequencyCookie) {
  if (!(frequencyCookie && frequencyCookie.name && frequencyCookie.expire)) return;
  if (adUnit.name !== slotNames.RICH_MEDIA_PREMIUM && adUnit.name !== slotNames.MOB_RICH_MEDIA_PREMIUM) return;
  cookieHelper.setCookie(frequencyCookie.name, true, frequencyCookie.expire / 24, undefined, "Lax");
}

function createSlotAttributes(adUnit) {
  const element = window.document.getElementById(adUnit.id);
  if (element === null) return;

  const creativeIds = element.getAttribute("data-creative-ids") ? element.getAttribute("data-creative-ids").split(",") : [];
  creativeIds.push(adUnit.adData.creativeId);
  element.setAttribute("data-creative-ids", creativeIds.join(","));

  const lineItemIds = element.getAttribute("data-line-item-ids") ? element.getAttribute("data-line-item-ids").split(",") : [];
  lineItemIds.push(adUnit.adData.lineItemId);
  element.setAttribute("data-line-item-ids", lineItemIds.join(","));
}

function impressionViewableEventListener(evt) {
  const eventData = parseEvent(evt, "impressionViewable");
  publishMessage("impressionViewable", eventData);
  const { campaignId, lineItemId, creativeId } = eventData.adData;
  if (campaignId && lineItemId && creativeId) {
    triggerExposedAdAudiencePixel(campaignId, lineItemId, creativeId);
  }
}

function triggerSlotRenderEndedEvents(adUnit, bauConfig) {
  publishMessage("slotRenderEnded", adUnit);

  if (!adUnit.isEmpty) {
    if (window.pbjs && window.pbjs.creatives) {
      adUnit = prebidCreativeSize(adUnit);
    }
    if (adUnit.adData.path.indexOf("passback") > -1) {
      const slotElement = window.document.getElementById(adUnit.id);
      trackAd(slotElement, window.top.innerHeight, "bottom", "passback", () => passbackHelper.resize(slotElement));
    }
    publishMessage("resize", adUnit);
  } else {
    if (bauConfig.smartCollapse) {
      const smartCollapseOffset = bauConfig.smartCollapse === "below" ? window.top.innerHeight : 0;
      const smartCollapseDirection = "top";
      trackAd(window.document.getElementById(adUnit.id), smartCollapseOffset, smartCollapseDirection, "smartCollapse", () => {
        publishMessage("collapse", adUnit);
      });
      publishMessage("empty", adUnit);
    } else {
      publishMessage("collapse", adUnit);
    }
  }
}

function prebidCreativeSize(adUnit) {
  const creatives = Array.prototype.slice.call(window.pbjs.creatives);
  const filteredCreatives = [];

  creatives.forEach((creative) => {
    if (creative.id === adUnit.adData.creativeId) {
      const size = creative.size.split("x");
      adUnit.size = {
        "width": parseInt(size[0]),
        "height": parseInt(size[1])
      };
    } else {
      filteredCreatives.push(creative);
    }
  });

  window.pbjs.creatives = filteredCreatives;

  return adUnit;
}

function triggerExposedAdAudiencePixel(campaignId, lineItemId, creativeId) {
  const axel = Math.random() + ''; // eslint-disable-line
  const a = axel * 10000000000000;
  (new Image()).src = `https://pubads.g.doubleclick.net/activity;dc_iu=/34405621/DFPAudiencePixel;ord=${a};dc_seg=480964028;campaignId=${campaignId};lineItemId=${lineItemId};creativeId=${creativeId}`;
}

function createAdLabel(bamAdSlotId, headerHeight) {
  const adLabel = document.createElement("div");
  adLabel.textContent = "Annons";
  adLabel.style.cssText = `
    color: white;
    background-color: rgba(0, 0, 0, 0.50);
    font-size: 16px;
    position: fixed;
    top: ${headerHeight ?? "0"};
    left: 0px;
    font-weight: 700;
    padding: 4px 8px;
    z-index: 1;
    border-radius: 0px 0px 2px 0px;
    backdrop-filter: blur(2px);
  `;

  document.getElementById(bamAdSlotId).appendChild(adLabel);
}

function createArrow(bamAdSlotId) {
  const arrow = document.createElement("div");
  arrow.innerHTML = `
    <svg width="21" height="15" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6001 0.834973L13.4501 2.70997L7.00007 9.15997L0.550073 2.70997L2.40007 0.834972L7.00007 5.43497L11.6001 0.834973Z" fill="white"/>
    </svg>
  `;
  arrow.style.cssText = `
    position: absolute;
    bottom: 16px;
    width: 52px !important;
    height: 52px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(2px);
    border-radius: 100px;
  `;
  arrow.addEventListener("click", () => {
    const contentStartHeight =
      window.visualViewport.pageTop +
      document.getElementById(bamAdSlotId).getBoundingClientRect().bottom +
      1; //+ 1 to make sure the ad has been scrolled down all the way.
    window.scrollTo({ behavior: "smooth", top: contentStartHeight });
  });
  document.getElementById(bamAdSlotId).appendChild(arrow);
}

function createCloseButton(bamAdSlotId) {
  const closeButton = document.createElement("div");
  closeButton.innerHTML = `
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.39999 19.45L4.54999 17.6L10.15 12L4.54999 6.40005L6.39999 4.55005L12 10.15L17.6 4.55005L19.45 6.40005L13.85 12L19.45 17.6L17.6 19.45L12 13.85L6.39999 19.45Z" fill="white"/>
    </svg>
  `;
  closeButton.style.cssText = `
    position: fixed;
    top: 16px;
    right: 16px;
    width: 52px !important;
    height: 52px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(2px);
    border-radius: 100px;
  `;
  closeButton.addEventListener("click", () => {
    /*
      There is a choice to be made if we decide on removing the google slot from the page or just scroll past.

      1. if we do remove it we should send out a event that the slot has been collapsed and let the site handle it.

      2. Scroll down to under it, this is the current implementation.
    */

    // Option 2.
    const contentStartHeight = window.visualViewport.pageTop +
      document.getElementById(bamAdSlotId).getBoundingClientRect().bottom + 1; //+ 1 to make sure the ad has been scrolled down all the way.

    const scrollEndEvent = window.addEventListener("scrollend", () => {
      window.removeEventListener("scrollend", scrollEndEvent);

      // Option 1.
      googletag.destroySlots([googletag.pubads().getSlots().find((slot) => slot.getSlotElementId() === bamAdSlotId)]);

      publishMessage("collapse", {
        slotName: bamAdSlotId.split("-")[0],
        slotNumber: bamAdSlotId.split("-")[1],
        id: bamAdSlotId,
      });

      getFullPageHeaderElement()?.parentElement.remove();
    });

    window.scrollTo({ behavior: "smooth", top: contentStartHeight });
  });
  document.getElementById(bamAdSlotId).appendChild(closeButton);
}

const isAncestor = (childWindow, frameWindow) => {
  if (frameWindow === childWindow) {
      return true;
  } else if (childWindow === window.top) {
      return false;
  }

  return childWindow?.parent ? isAncestor(childWindow.parent, frameWindow) : null;
};

function getFullPageHeaderElement() {
  const selectors = [
      ".ad-fullpage__header",
      ".fullpage-ad__header",
      "#closePremiumAdWrapper",
      "#fullscreen-ad:first-child",
      "#rich-media-wrapper:first-child",
      "#ad-welcome:first-child"
    ];
  return selectors.map((selector) => document.querySelector(selector)).find((element) => element !== null);
}

function getHeaderElement() {
  const selectors = [
      "[data-component-name='Header']",
      ".site-header__wrapper",
      ".site-header__row-1",
      "#navbar",
      ".sticky-header",
      ".sticky-menu",
      "#menu-sm",
      ".header-grid",
      ".site-header > div",
  ];
  return selectors
      .map((selector) => document.querySelector(selector))
      .find((element) => element !== null);
}

function getBottomElement() {
  const selectors = [".sticky.bottom-0"];
  return selectors
    .map((selector) => document.querySelectorAll(selector)[0])
    .find((element) => element !== null);
}

function checkVisible(elm) {
  try {
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  } catch (e) {
    return false;
  }
}

function getHeaderHeight() {
  const header = getHeaderElement();
  if (!header) return "0px";
  const headerHeight = header.offsetHeight;
  const afterStyle = getComputedStyle(header, "::after");
  const afterHeight = parseFloat(afterStyle.height) || 0;
  return `${headerHeight + afterHeight}px`;
}

function messageHandler(event) {
  if (!event.data) return;

  let data, bamAdSlot, adIframe;

  try {
    data = JSON.parse(event.data);

    const frames = document.querySelectorAll("iframe[id*='google_ads_iframe']");
    adIframe = Array.from(frames).find((frame) => isAncestor(event.source, frame.contentWindow));

    bamAdSlot = adIframe.closest(".bam-ad-slot");

    if (!bamAdSlot) return;
  } catch (e) {
    return;
  }

  if (data.action === "responsive") {
    const fullpageAdHeader = `${getFullPageHeaderElement() ? getFullPageHeaderElement().offsetHeight : 0}px`;
    const adIframeId = adIframe.id;
    const responsiveStyle = document.createElement("style");
    responsiveStyle.innerHTML = `
      [data-slot-name='rich_media_premium'] [id*='${adIframeId}'], 
      [data-slot-name='mob_rich_media_premium'] [id*='${adIframeId}'] {
        width: 100% !important;
        height: 100% !important;
        transform: none !important;
      }
      
      [data-slot-name='rich_media_premium'], [data-slot-name='mob_rich_media_premium'] {
        height: calc(100% - ${fullpageAdHeader}) !important; 
      }
    `;
    document.head.appendChild(responsiveStyle);
  } else if (data.highImpactRequest ) {
    const bamAdSlotToHighImpactFormats = {
      mob: ["midscroll", "mobile_mega"],
      "mob_rich_media_premium": ["topscroll", "topscroll_gaming"],
      "rich_media_premium": ["topscroll", "topscroll_gaming"],
    };

    const adIframeId = adIframe.id;
    const bamAdSlotId = bamAdSlot.id;

    if (adIframeId === "" || bamAdSlotId === "") return;

    if (data.action === "highImpactSupportedFormat") {
      event.source.postMessage(
        JSON.stringify({
          action: bamAdSlotToHighImpactFormats[bamAdSlot.dataset.slotName],
        }),
        "*"
      );
    } else if (data.action === "midscroll") {
      const adWrapper = document.getElementById(bamAdSlotId);
      let headerHeight = getHeaderHeight();
      const midscrollStyle = document.createElement("style");

      if (!checkVisible(getHeaderElement())) {
        headerHeight = "0px";
      }

      if (
        window.Bau.bamData.path.includes("/bn/hdsyd") ||
        window.Bau.bamData.path.includes("/mm/") ||
        window.Bau.bamData.path.includes("/hm/") ||
        window.Bau.bamData.path.includes("/hbl/") ||
        window.Bau.bamData.path.includes("/gotamedia/")
      ) {
        midscrollStyle.textContent = `
          .ad-text:has(+ #${bamAdSlotId}) {
            display: none !important;
          }
        `;
        adWrapper.parentElement.style.cssText = `
          margin: 0px !important;
          transform: none !important;
          contain: none !important;
        `;
      }

      const bottomElement = getBottomElement();
      const bottomHeight = bottomElement ? bottomElement.offsetHeight : 0;

      const labelHeight = `calc(100vh - ${headerHeight} - ${bottomHeight}px)`;

      // Uncomment this to find the header height instead.
      // const headerHeight = `${[".site-header__wrapper", ".site-header__row-1", "#navbar", ".sticky-header", ".sticky-menu", "#menu-sm", ".header-grid", ".site-header > div"].map((e => document.querySelector(e))).find((e => null !== e))?.offsetHeight || 0}px`;

      midscrollStyle.textContent += `
        div #${bamAdSlotId} {
          height: ${labelHeight} !important;
          clip-path: polygon(0px 0px, 100vw 0px, 100vw ${labelHeight}, 0px ${labelHeight}) !important;
          margin: 0px 0px 0px 0px !important;
          padding: 0px !important;
          position: relative !important;
          width: 100vw !important;
          transform: none !important;
        }

        [id='${bamAdSlotId}'] [id*='${adIframeId}'], [id='${bamAdSlotId}'] [id*='${adIframeId}'] {
          height: calc(100vh - ${headerHeight} - ${bottomHeight}px) !important;
          width: 100vw !important;
          clip: rect(auto, auto, auto, auto) !important;
          position: fixed !important;
          left: 0px !important;
          top: ${headerHeight} !important;
          transform: none !important;
        }

        [id='${bamAdSlotId}'] [class*='ad-info_button'] {
          display: none !important;
        }
      `;

      document.head.appendChild(midscrollStyle);

      createAdLabel(bamAdSlotId, headerHeight);
    } else if (data.action.includes("topscroll")) {
      const adWrapper = document.getElementById(bamAdSlotId);
      const topScrollStyle = document.createElement("style");
      let adSlotMinHeight = "0px"; // Fallback

      if (data.action === "topscroll") {
        let headerHeight = 80;

        if (document.querySelector(".instrument-ticker__wrapper")) {
          // Dagens Industri, platform Flamingo - top bar for stock market ticker
          headerHeight += 36;
        } else if (document.querySelector(".top-bar__link")) {
          // Local sites, platform Jalla - top bar for subscription banner
          headerHeight += 56;
        } else if (document.querySelector(".css-ri8ryi")) {
          // BBM, platform Kameleont - header with more height
          headerHeight += 54; // Total header size is 134px

          // Special case for Privata Affärer - top bar for stock market ticker
          if (document.querySelector(".css-tkolrl")) {
            headerHeight += 44;
          }
        }

        adSlotMinHeight = `calc(100svh - ${headerHeight}px)`;
      } else {
        adSlotMinHeight = "70svh";
      }

      if (
        window.Bau.bamData.path.includes("/bn/hdsyd") ||
        window.Bau.bamData.path.includes("/mm/") ||
        window.Bau.bamData.path.includes("/hm/") ||
        window.Bau.bamData.path.includes("/hbl/") ||
        window.Bau.bamData.path.includes("/gotamedia/")
      ) {
        topScrollStyle.textContent = `
          .ad-text:has(+ #${bamAdSlotId}) {
            display: none !important;
          }`;
        adWrapper.parentElement.style.cssText = `
          margin: 0px !important;
          transform: none !important;
          contain: none !important;
          --ad-slot-minheight: ${adSlotMinHeight} !important;
        `;
      }

      topScrollStyle.textContent = `
        .ad-welcome, #premiumAdWrapper, #fullscreen-ad {
          height: ${adSlotMinHeight} !important;
        }

        .ad-take-over__header, .ad-welcome__header, .fullpage-ad__header, 
        .ad-fullpage__header, #closePremiumAdWrapper, #fullscreen-ad > :first-child, 
        #rich-media-wrapper > :first-child, #ad-welcome > :first-child {
          display: none !important;
          visibility: hidden;
          height: 0;
          width: 0;
          overflow: hidden;
          padding: 0 !important;
        }

        div:has(> div#${bamAdSlotId}) {
          height: ${adSlotMinHeight} !important;
        }

        div#${bamAdSlotId} {
          height: ${adSlotMinHeight} !important;
          clip-path: polygon(0px 0px, 100vw 0px, 100vw ${adSlotMinHeight}, 0px ${adSlotMinHeight}) !important;
          margin: 0px !important;
          padding: 0px !important;
          position: relative !important;
          --ad-slot-minheight: none;
          width: 100vw !important;
        }

        [data-slot-name='rich_media_premium'] [id*='${adIframeId}'],
        [data-slot-name='mob_rich_media_premium'] [id*='${adIframeId}'] {
          height: ${adSlotMinHeight} !important;
          width: 100vw !important;
          clip: rect(auto, auto, auto, auto) !important;
          position: fixed !important;
          left: 0px !important;
          transform: none !important;
        }
      `;
      document.head.appendChild(topScrollStyle);

      createAdLabel(bamAdSlotId);
      createArrow(bamAdSlotId);
      createCloseButton(bamAdSlotId);

    } else if (data.action === "mobile_mega") {
      const adWrapper = document.getElementById(bamAdSlotId);

      const adBounds = adWrapper.getBoundingClientRect();

      const leftMargin = window.getComputedStyle(adWrapper).marginLeft;

      const calculatedLeftMargin = adBounds.left - parseInt(leftMargin, 10);

      const mobileMegaStyle = document.createElement("style");

      if (
        window.Bau.bamData.path.includes("/bn/hdsyd") ||
        window.Bau.bamData.path.includes("/mm/") ||
        window.Bau.bamData.path.includes("/hm/") ||
        window.Bau.bamData.path.includes("/hbl/") ||
        window.Bau.bamData.path.includes("/gotamedia/")
      ) {
        adWrapper.parentElement.style.cssText = `
          margin: 0px !important;
          transform: none !important;
          contain: none !important;
        `;
      }

      //För att ta höjd för annonslabel
      let adHeight = "568";
      if (window.Exp && window.Exp.platformName === "maisha") {
        adHeight = "591";
      }

      mobileMegaStyle.textContent += `
        div:has(> [id='${adIframeId}']) {
          width: 100% !important;
          height: 568px !important;
          transform: none !important;
        }

        [id='${adIframeId}'] {
          width: 100% !important;
          height: 568px !important;
          transform: none !important;
        }

        div#${bamAdSlotId} {
          margin: 0px -${calculatedLeftMargin}px 0px -${calculatedLeftMargin}px !important;
          width: 100vw !important;
          height: ${adHeight}px !important;
          min-height: 568px !important;
        }
      `;

      document.head.appendChild(mobileMegaStyle);
    }
  }
}

export default eventListeners;

export { messageHandler };
