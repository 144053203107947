export default [... new Set([
  // Testing line items //
  6141670212, 6150604282, 6220022817, 6220022820, 6220391675, 6220392935, // PG OR PD
  6223284198, 6258419440, 6275895264, 6384730251,
  6387689999, 6406534270, 6418158188, 6421336938, 6422867924,
  6426385101, 6480860321, 6489656133, 6510904501, 6513763571, 6545574601,
  6581621744, 6650223489, 6334257780, 6204484938, 6204485178, 6208071786,
  6208260957, 6258460297, 6265324705, 6393196962, 6393591577, 6394248131,
  6428664394, 6477293407, 6494384108, 6696548522, 6233069936,
  6366316697, 6143047876, 6141088030, 6150604285, 6204482787, 6204485664,
  6210477557, 6219702205, 6220392932,
  6223211955, 6223284201, 6256636557, 6258460759, 6139650636,
  6266023583, 6275895267, 6366317246, 6406533979, 6417563959,
  6422867921, 6478163919, 6492491645, 6493810726, 6510903583, 6513762947,
  6527179635, 6650223492, 6695520934, 6334257783, 6393196971, 6393591034,
  6394247975, 6428662684, 6717591091, 6703515820, 6475097691, 6401451105,
  6856435142, // PG OR PD

  // IO LINE ITEMS //
  6844233303, 6842056494,

  //TEST LINE ITEMS //
  6789420442,

// AD EXCHANGE EXPRESSEN TAKEOVER & WELCOME PAGE //
  6840610032, 6842904922,
])];
